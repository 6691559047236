import React from "react"
import { Wrapper, Container } from "../styles"
import IconBar from "../components/IconBar"
import SEO from "../components/seo"

const ToolkitPage = () => {
  const icons = [
    // {
    //   isExternalLink: true,
    //   iconName: "training",
    //   title: "Sales Training",
    //   link:
    //     "https://drive.google.com/drive/folders/1LhvUc9gYOwmBis15Oawv4DvZOZpAI0hm?usp=sharing",
    //   id: "pro-toolkit-link-training",
    // },
    {
      isRebate: true,
      iconName: "money",
      title: "Utility Rebate Finder",
      id: "pro-toolkit-link-rebate",
    },
    // {
    //   isExternalLink: true,
    //   iconName: "folder",
    //   title: "Marketing Materials",
    //   link:
    //     "https://drive.google.com/drive/folders/13JnnOePQz3EeMN76zwLXYW9-Fl6PGYXv?usp=sharing",
    //   id: "pro-toolkit-link-marketing",
    // },
  ]
  return (
    <>
      <SEO title="Sales Toolkit" pathname="/toolkit/" />
      <Wrapper width="tight">
        <div css="text-align: center">
          <h1 css="color: var(--primaryColor)">
            Smart homeowners deserve a smart sprinkler controller.
          </h1>
          <p className="largest">
            Customers love the savings, smartphone control and care-free
            watering of Rachio – and you’ll love the easy sale.
          </p>
        </div>
        <hr css="padding-top: var(--basePadding)" />
        <Container>
          <IconBar icons={icons} justifyContent="center" />
        </Container>
      </Wrapper>
    </>
  )
}

export default ToolkitPage
