import React, { useState } from "react"
import styled, { css } from "styled-components"
import { Link } from "gatsby"
import { media, Card } from "../styles"
import { Button } from "../components/Button"
import Icon from "../components/Icon"
import Modal from "../components/Modal"
import Rebate from "../components/Rebate"

type IconType = {
  isExternalLink?: boolean
  iconName: string
  title: string
  link?: string
  id?: string
  isRebate?: boolean
}

type Props = {
  icons: IconType[]
  justifyContent?: string
  isSmall?: boolean
  isBlue?: boolean
}

const IconBar = ({
  icons,
  justifyContent = "",
  isSmall = false,
  isBlue = false,
}: Props) => {
  const [isActive, setIsActive] = useState(false)

  return (
    <LinkContainer
      justifyContent={justifyContent}
      isSmall={isSmall}
      isBlue={isBlue}
    >
      {icons.map((icon: IconType) => (
        <li key={icon.title}>
          {icon.link ? (
            <>
              {icon?.isExternalLink ? (
                <a
                  id={icon.id}
                  href={icon.link}
                  target="_blank"
                  rel="nofollow noopener noreferrer"
                >
                  <Icon
                    color={isBlue ? "var(--white)" : "var(--primaryColor)"}
                    name={icon.iconName}
                  />
                  <p>{icon.title}</p>
                </a>
              ) : (
                <Link id={icon.id} to={icon.link}>
                  <Icon
                    color={isBlue ? "var(--white)" : "var(--primaryColor)"}
                    name={icon.iconName}
                  />
                  <p>{icon.title}</p>
                </Link>
              )}
            </>
          ) : (
            <>
              {icon.isRebate ? (
                <>
                  <Icon
                    onClick={() => setIsActive(true)}
                    name={icon.iconName}
                    style={{ cursor: "pointer" }}
                    color={isBlue ? "var(--white)" : "var(--primaryColor)"}
                  />
                  {isBlue ? (
                    <Button
                      color="transparent"
                      size="small"
                      css="margin: 1rem auto 0; display: block"
                      onClick={() => setIsActive(true)}
                    >
                      {icon.title}
                    </Button>
                  ) : (
                    <RebateButton onClick={() => setIsActive(true)}>
                      {icon.title}
                    </RebateButton>
                  )}

                  <Modal
                    isActive={isActive}
                    closeAction={() => setIsActive(false)}
                    maxWidth="95%"
                  >
                    <Card css="background: var(--white)">
                      <Rebate />
                    </Card>
                  </Modal>
                </>
              ) : (
                <>
                  <Icon
                    name={icon.iconName}
                    color={isBlue ? "var(--white)" : "var(--darkerGray)"}
                  />
                  <p css="margin-top: 0;">{icon.title}</p>
                </>
              )}
            </>
          )}
        </li>
      ))}
    </LinkContainer>
  )
}

export default IconBar

const LinkContainer = styled.ul`
  display: flex;
  flex-direction: column;
  text-align: center;
  align-items: center;
  padding: var(--basePadding);
  background: var(--lighterestGray);
  li {
    flex: 1;
    margin: 2rem;
    a {
      height: 48px;
      width: 48px;
      svg {
        height: inherit;
        width: inherit;
      }
    }
  }
  ${({ isSmall }: { isSmall?: boolean }) =>
    isSmall &&
    css`
      li {
        a,
        svg {
          width: 32px;
          height: 32px;
        }
        p {
          margin-top: 0;
          font-size: var(--smallFontSize);
        }
      }
    `};
  ${({ isBlue }: { isBlue?: boolean }) =>
    isBlue &&
    css`
      background: var(--primaryColor);
      color: var(--white);
    `};
  ${media.small`
    justify-content: ${({ justifyContent }: { justifyContent?: string }) =>
      justifyContent || "space-between"};
    flex-direction: row;
    align-items: flex-start;
  `};
`

const RebateButton = styled.button`
  margin: 2rem auto 0;
  display: block;
  &:hover {
    color: var(--primaryBlue);
  }
`
